import React from "react";
import { Helmet } from "react-helmet";
export default (props) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />

      <title>
        {props.title
          ? `${props.title} | Premise Healthcare`
          : "Premise Healthcare"}
      </title>

      <meta name="description" content={`${props.dis}`} />
      <meta property="og:title" content="Premise Healthcare" />
      <meta property="og:locale" content="uk_ua" />
      <meta property="og:url" content="https://careasist.co.uk/" />
      <meta property="og:type" content="Website" />
      <meta property="og:description" content="Premise Healthcare" />
    </Helmet>
  );
};
